
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isLoggedIn: false,
      showModal: false,
      deleteUserId: {
        loading: false,
        success: false,
        formError: null,
        errored: false
      },
    }
  },
  computed: {
    isLoggedInStore() {
      if (process.browser) {
        let token = localStorage.getItem("authToken") ? localStorage.getItem("authToken") : null
        this.$store.commit("islogingin", token);
      }
      return this.$store.state.isLogIn
    },
    profileInStore() {
      if (process.browser) {
        if (localStorage.getItem("profile") && JSON.parse(localStorage.getItem("profile")).username)
          this.$store.commit("profile", JSON.parse(localStorage.getItem("profile")).username);
      }
      return this.$store.state.profile
    },
  },
  watch: {
    isLoggedInStore() {
      if (process.browser) {
        let token = localStorage.getItem("authToken") ? localStorage.getItem("authToken") : null
        this.$store.commit("islogingin", token);
      }
    },
    profileInStore() {
      if (process.browser) {
        if (localStorage.getItem("profile") && JSON.parse(localStorage.getItem("profile")).username)
          this.$store.commit("profile", JSON.parse(localStorage.getItem("profile")).username);
      }
      return this.$store.state.profile
    },
  },
  methods: {
    logout() {
      this.isLoggedIn = false;
      localStorage.removeItem('authToken');
      this.$store.commit("logout", true);
      this.$router.push({
        path: '/accounts/login/'
      })
    },
    closeModal() {
      this.deleteUserId.success = false;
      this.deleteUserId.formError = null;
      this.deleteUserId.errored = false;
      this.showModal = false;
      this.$bvModal.hide('delete')
    },

    deleteUser() {
      this.deleteUserId.formError = null;
      this.deleteUserId.success = false;
      this.deleteUserId.errored = false;
      this.deleteUserId.loading = true;
      if (localStorage.getItem("profile") && localStorage.getItem("authToken")) {
        let userData = JSON.parse(localStorage.getItem("profile"))
        let token = localStorage.getItem("authToken") ? localStorage.getItem("authToken") : null
        // Add header for the API
        const headers = {
          "Content-Type": "application/JSON",
          'Authorization': token
        };
        this.$axios.delete("/api/users/" + userData._id, {
          headers: headers
        })
          .then(response => {
            this.deleteUserId.success = true;
            this.deleteUserId.errored = false;
            this.isLoggedIn = false;
            localStorage.removeItem('authToken');
            this.$store.commit("logout", true);
          })
          .catch(error => {
            this.deleteUserId.success = false;
            this.deleteUserId.errored = true;
          })
          .finally(() => {
            this.deleteUserId.loading = false;
          })
      }
    },
    closeModalAfterSuccess() {
      this.deleteUserId.success = false;
      this.deleteUserId.formError = null;
      this.deleteUserId.errored = false;
      this.showModal = false;
      this.isLoggedIn = false;
      localStorage.removeItem('authToken');
      this.$store.commit("logout", true);
      this.$bvModal.hide('delete')
      this.$router.push({
        path: '/'
      })
      document.body.classList.remove('modalOverflow');
    }
  }
}

